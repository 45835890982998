/* eslint-disable no-console */

import { v4 } from 'uuid'

import { env } from '@/env'

interface BaseEvent {
  userId?: string
  email?: string
  pathname?: string
  url?: string
  blockName?: string
  blockId?: string
  blockType?: string
  pageId?: string
  pageSlug?: string
  siteId?: string
  siteSlug?: string
}

interface EnrichedEvent extends BaseEvent {
  transactionId: string
  event: string
  timestamp: string
  referrer: string
  title: string
  screenHeight: number
  screenWidth: number
  viewportHeight: number
  viewportWidth: number
}

export const useEvents = () => {
  const sendEvent = async (_eventName: string, data: any) => {
    try {
      const response = await fetch(
        `${env.NEXT_PUBLIC_EVENTS_BASE_URL}/capture`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
          },
          body: JSON.stringify(data),
          credentials: 'omit',
          keepalive: true,
        },
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
    } catch (error) {
      console.error('Failed to send event:', error)
    }
  }

  const capture = (eventName: string, properties: BaseEvent) => {
    if (['prod-screenshot.fingertip.com'].includes(document.referrer)) {
      return
    }

    const eventData: EnrichedEvent = {
      transactionId: v4(),
      event: eventName,
      timestamp: new Date().toISOString(),
      referrer: document.referrer,
      title: document.title,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
      ...properties,
    }

    sendEvent(eventName, eventData)
  }

  return { capture }
}
